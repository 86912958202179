import {Player} from '@lottiefiles/react-lottie-player';
import React from 'react';
import useLocale from '@/hooks/useLocale';
import styles from './notfound.module.css';

const NotFound: React.FC = () => {
    const locale = useLocale();

    return (
        <article className={styles.content}>
            <h1>{locale.pages['404']}</h1>
            <div className={`${styles.animation} chromatic-ignore`}>
                <Player
                    autoplay
                    loop
                    src='/error.json'
                />
            </div>
        </article>
    );
};

export default NotFound;
