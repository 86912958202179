import Layout from '@/components/layout';
import NotFound from '@/components/notfound';

const Page: React.FC = () => (
    <Layout hasError>
        <NotFound />
    </Layout>
);

export default Page;
